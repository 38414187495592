import React, { useState } from "react"
import supabase from "../services/supabase"
import { Box, Button, Text, Input, Label } from "theme-ui"
import { i18nContext } from "../context/i18nContext"

const ResetPasswordForm = ({}) => {
  const isBrowser = typeof window !== "undefined"
  const redirectTo = isBrowser
    ? `${window?.location?.origin}/set-password/`
    : ""
  const [email, setEmail] = useState("")
  const [error, showError] = useState(false)
  const [confirmation, showConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    showError(false)

    supabase.auth
      .resetPasswordForEmail(email, {
        redirectTo: redirectTo,
      })
      .then(({ error }) => {
        if (error) {
          setLoading(false)
          showError(true)
          console.log("error", error)
        } else {
          setLoading(false)
          showConfirmation(true)
          console.log("Reset instructions sent")
        }
      })
  }

  return (
    <i18nContext.Consumer>
      {t => (
        <Box as="form" onSubmit={handleSubmit}>
          <Box sx={{ position: "relative" }}>
            <Label variant="label.kicker" htmlFor="email">
              Email *
            </Label>
            <Input
              onChange={e => setEmail(e.target.value)}
              type="email"
              name="email"
              id="email"
              value={email}
              autoComplete="email"
              required
              sx={{ mb: 4 }}
            />
          </Box>
          {error && (
            <Text as="p" sx={{ color: "red", mb: [3] }}>
              {t.wrongCredentials}
            </Text>
          )}
          {confirmation && (
            <Text as="p" sx={{ color: "dark", mb: [3] }}>
              {t.resetPasswordConfirmation}
            </Text>
          )}
          <Button>{loading ? t.loading : t.sendResetInstruction}</Button>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default ResetPasswordForm
